<template>
  <VDialog class="gap-6">
    <div class="flex flex-col items-center text-center gap-4">
      <VIcon v-if="props.icon" :name="props.icon" />
      <VHeading v-if="props.title" level="4" medium>
        {{ props.title }}
      </VHeading>
      <VParagraph>
        {{ props.message }}
      </VParagraph>
    </div>
    <template #buttons>
      <VButton v-for="(button, buttonIndex) of buttons" :key="buttonIndex" variant="text" @click="emit('close', button.value)">
        {{ button.label }}
      </VButton>
    </template>
  </VDialog>
</template>
<script lang="ts" setup>
import VIcon from '@component-library/labels/VIcon.vue';
import VDialog from './VDialog.vue'
import VButton from '@component-library/buttons/VButton.vue';
import type { Icon } from '@icons/index';
import { computed } from 'vue';
import type { V } from '@component-utils/types';
import VParagraph from '@component-library/labels/VParagraph.vue';
import VHeading from '@component-library/labels/VHeading.vue';

defineOptions({
  name: 'VSimpleDialog'
})

const props = withDefaults(
  defineProps<{
    message: string
    icon?: Icon
    title?: string
    buttons?: (keyof typeof PREDEFINED_BUTTONS | V.SimpleDialog.Button)[]
  }>(),
  {
    icon: undefined,
    title: undefined,
    buttons: () => ['close']
  }
)

const emit = defineEmits<{
  close: [V.SimpleDialog.Button['value']]
}>()

const buttons = computed(() => props.buttons.map((button) => typeof button === 'string' ? PREDEFINED_BUTTONS[button] : button))

const PREDEFINED_BUTTONS = {
  'close': {
    value: false,
    label: window.localizeText('component-library.dialogs.default_close_label')
  },
  'continue': {
    value: true,
    label: window.localizeText('component-library.dialogs.default_continue_label')
  },
  'ok': {
    value: true,
    label: window.localizeText('component-library.dialogs.default_ok_label')
  },
  'confirm': {
    value: true,
    label: window.localizeText('component-library.dialogs.default_confirm_label')
  },
  'cancel': {
    value: false,
    label: window.localizeText('component-library.dialogs.default_cancel_label')
   }
} as const
</script>